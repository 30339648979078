.table{
    width: 600px;
    margin: 0 auto;
    border-collapse: collapse;
}
  
.table .tr{
    border-bottom: solid 2px white;
  }
  
.table .tr:last-child{
    border-bottom: none;
  }
  
.table .th{
    position: relative;
    text-align: left;
    width: 30%;
    background-color: #FDC04F;
    color: white;
    text-align: center;
    padding: 10px 0;
}
  
.table .th:after{
    display: block;
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    top:calc(50% - 10px);
    right:-10px;
    border-left: 10px solid #FDC04F;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
  
.table .td{
    text-align: left;
    width: 70%;
    text-align: center;
    background-color: #eee;
    padding: 10px 0;
}

@media screen and (max-width:600px) {
    .table {
        width: 300px;                
    }
    .table .th {
        font-size: 12px;
    }    
    .table .td {
        font-size: 12px;
    }    
}